<template>
  <div class="wxcode">
    <el-dialog
      title="微信支付"
      :visible.sync="dialogStatus"
      center
      width="40%"
      :before-close="closeDialog"
    >
      <div class="wxcode-main">
        <p>微信扫一扫付款(元)</p>
        <p class="wxcode-main-val">{{ price }}</p>
        <div class="code" style="width: 150px; margin: 0 auto">
          <div class="qrcode img" id="qrcode" ref="qrCodeUrl"></div>
        </div>
        <!-- <div class="qrcode img" ref="qrCodeUrl"></div> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    codeUrl: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: 0,
    },
    orderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogStatus: false,
      createNativeData: {},
      timer: null,
      imgUrl: "",
      checkOrderId: "",
    };
  },
  watch: {
    status(newV) {
      this.dialogStatus = newV;
    },
    codeUrl(newV) {
      this.imgUrl = newV;
      if (this.imgUrl) {
        this.createCode();
      }
    },
    orderId(newV) {
      this.checkOrderId = newV;
    },
  },
  created() {
    this.dialogStatus = this.status;
    this.imgUrl = this.codeUrl;
    this.checkOrderId = this.orderId;
    if (this.imgUrl) {
      this.createCode();
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      clearInterval(this.timer);
    },
    createCode() {
      this.$nextTick(() => {
        setTimeout(() => {
          document.getElementById("qrcode").innerHTML = "";
          new QRCode(this.$refs.qrCodeUrl, {
            text: this.imgUrl,
            width: 150,
            height: 150,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }, 500);
      });
      this.timer = setInterval(() => {
        let data = {
          orderNo: this.checkOrderId,
        };
        this.api.goldcoin.checkPay(data).then((res) => {
          if (res.code == 20000) {
            clearInterval(this.timer);
            // this.$notice({
            //   type: "success",
            //   message: "支付成功",
            // }).isShow();
            this.$message({
              message: res.message,
              type: "success",
            });
            this.$emit("close", 1);
          }
        });
      }, 5000);
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang="scss">
img {
  margin: 0 auto;
}
.wxcode {
  .wxcode-main {
    text-align: center;
    color: #333;
    font-size: 16px;
  }

  .wxcode-main-val {
    color: #cb1b31;
    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
  }
  .qrcode {
    margin-top: 20px;
    img {
      margin: 0 auto;
    }
  }
}
</style>
<style lang="scss">
.wxcode {
  .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }
  .el-dialog__header {
    height: 30px;
    background: #b93138;
    // line-height:20px;
    font-size: 18px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }

    .el-dialog__headerbtn {
      // line-height:20px;

      .el-dialog__close {
        color: #ffffff;
      }
    }
  }
}
</style>