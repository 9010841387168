<template>
  <div class="contract">
    <el-dialog
      title="用户金币充值协议"
      :visible.sync="dialogStatus"
      center
      width="40%"
      :before-close="closeGoldAgree"
    >
      <div class="hetong">
        <p>
          全国足球特色幼儿园系统平台充值协议（以下称“本协议”）是由您与全国足球特色幼儿园系统平台（以下称“本平台”）运营主体小牛启航体育（北京）有限公司（以下称“公司”）签订；本平台按照本协议规定及不时发布的操作规则提供基于互联网的充值服务（以下称“充值服务”）。为更好地向用户提供充值服务，请服务使用人（以下称“用户”或“您”）开始使用充值服务之前认真阅读并充分理解本协议，其中，免除或者限制责任条款等重要内容将以加粗形式提示注意，请您重点阅读。如您自主选择按照页面提示完成充值程序，则视为您已同意并接受本协议项下的全部条款。如您不同意本协议，请您立即停止充值。
        </p>
        <h2>第一章 服务规则</h2>
        <p>
          1、“金币”是本平台向您提供的用于在本平台上进行相关消费的虚拟币，您可以用“金币”购买本平台上产品或服务（具体可购买产品和服务请查看相应产品、服务页面说明）。
        </p>
        <p>
          2、您充值购买“金币”的费用将由公司或公司指定的合作渠道商向您收取。各充值方式对应的渠道商可能会按其标准制定相关充值渠道手续费用，并在用户充值时收取，请用户务必注意各充值方式的渠道商服务手续费，并按自身需求选取充值方式。
        </p>
        <p>
          3、平台倡导理性消费，请您务必根据自身实际需求购买相应数量的“金币”，同时，在充值过程中，请您务必谨慎确认您充值的账号或账号绑定的手机号码，并仔细选择相关操作选项。若因您自身输入帐号/账号绑定手机号错误、金额错误、操作不当或不了解、未充分了解充值计费方式等因素造成充错帐号、充值金额错误、错选充值种类等情形而导致自身权益受损的，由此带来的损失由您自行承担，您无权要求本平台作出补偿或赔偿。
        </p>
        <p>
          4、请您务必使用本平台指定的官方充值方式或指定渠道商进行充值。若您使用非本平台所指定的充值方式或非法的方式进行充值，本平台不保证该充值顺利或者正确完成，同时可能引发其他风险，若因此造成您或任何第三方权益受损，由您自行应对处理并承担相应责任，您无权要求本平台作出任何补偿或赔偿，同时本平台保留随时终止您的帐号部分或全部权限、冻结您的帐户余额、短期或永久封禁您的帐号及/或禁止您使用各项充值服务的权利。
        </p>
        <p>
          5、如果本平台发现因系统故障或其他任何原因导致的处理错误，无论有利于本平台还是有利于您，本平台都有权纠正该错误。如果该措施导致您实际收到的“金币”数量少于您应获得的“金币”，则本平台在确认该处理错误后会尽快将差额补足至您的帐户中。如果该错误导致您实际收到的“金币”数量多于您应获得的“金币”，则无论错误的性质和原因如何，本平台有权从您的帐户中直接扣除差额。
        </p>
        <p>
          6、根据公司产品功能，您的充值帐号在公司运营的其他产品、平台（以下称“关联平台”）购买的虚拟币，可能在本平台与您购买的“金币”合并计算并具备同等使用范围，此时，您在本平台使用关联平台虚拟币的行为同样受本协议约束（无论前述关联平台虚拟币在本平台被叫做“金币”或其他名称）；反之，您的金币通过上述方式在关联平台使用的，您在关联平台使用“金币”的行为，将同时受关联平台充值协议及相关规则条款约束。
        </p>
        <p>
          7、为更好的向您提供产品服务，平台会收集记录您充值购买金币并在本平台相关产品或服务中使用金币所产生的数据，包括但不限于您充值后购买产品或服务、关联平台使用金币所产生的行为信息，以及您的金币余额等账户信息。
        </p>
        <p>
          8、您使用本协议项下充值服务时，相关金币服务由与本平台合作的第三方机构或渠道商向您提供服务的，第三方机构或渠道商可能需要收集您的姓名、注册名、ID号、账号、手机号码及您在本平台的必要资料信息，以核实并为您处理资金或金币结算事项。
        </p>
        <h2>第二章 权利声明</h2>
        <p>
          1、您理解并同意，本平台有权基于交易安全、运营策略、行业惯例等方面的考虑及时设定并调整涉及交易的相关限制和提醒事项，包括但不限于交易限额、交易次数等。
        </p>
        <p>
          2、如充值过程中涉及由第三方提供的相关服务，则除遵守本协议约定外，您还需要同意并遵守该第三方的协议及相关规则，在任何情况下，对于该第三方及其提供的相关服务而产生的争议由您自行与该第三方解决，本平台无需就此向您或该第三方承担任何责任。
        </p>
        <p>
          3、因您自身的原因导致本平台无法提供“金币”购买服务或提供“金币”购买服务时发生任何错误而产生的任何损失或责任，由您自行负责，您无权要求本平台承担责任，包括但不限于：
        </p>
        <p>（1）因您的帐号失效、丢失、被封停；</p>
        <p>
          （2）因您绑定的第三方支付机构、银行等帐户的原因导致的损失或责任，包括您使用未经认证的帐户或使用非您本人的帐户，您的帐户被冻结、查封等；
        </p>
        <p>（3）您将密码告知他人导致的财产损失；</p>
        <p>（4）因您个人的故意或重大过失所造成的财产损失。</p>
        <p>
          4、您保证用于“金币”充值的资金均为您的合法收入所得，且您有充分权利按照本协议约定使用，因您违反本条而产生的任何争议或纠纷，由您自行处理解决，并承担由此带来的全部责任及法律后果。同时，平台发现（包括但不限于主动发现、收到第三方投诉或权力机关通知等情形）存在上述事由，有权单方决定限制您使用全部或部分充值“金币”、暂停或终止向您提供充值服务或对您的帐号采取进一步管理措施；如因此给平台造成损失的，您应予以全额赔偿，同时，平台有权保存相关信息，并向相关执法/司法机构、主管部门报告。
        </p>
        <p>
          5、若您使用平台为您开通的专属充值账号服务（如开通），您应遵守所有适用的与反洗钱、反诈骗、反恐怖主义融资、经济制裁相关的法律法规（“反洗钱相关法律规定”）。您承诺不得利用该专属收款账号进行洗钱等任何非法活动，也不得接受他人利用您通过该专属充值账号完成洗钱等的任何非法要求。若该专属充值账号发生任何异常交易，平台有权根据反洗钱相关法律规定及/或监管机构要求采取暂停该专属充值账号、展开调查等措施，且无须承担您因此所遭受的任何损失或损害。您确认您向平台提供的身份信息及资料完全真实有效，并且愿意按照法律规定及平台的要求协助和配合监管机构的调查。
        </p>
        <p>
          6、在任何情况下，本平台均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害承担责任。本平台对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用本平台提供的充值服务而支付的费用。
        </p>
        <h2>第三章 处罚规则</h2>
        <p>
          1、如发生下列任何一种情形，本平台有权随时中断或终止向您提供本协议项下的网络服务（而无需另行单独通知您），且无需承担因充值服务中断或终止而给您或任何第三方造成损失的任何责任：
        </p>
        <p>（1）您提供的用户资料不真实；</p>
        <p>（2）您违反本协议或其他本平台对用户的管理规定；</p>
        <p>（3）您以非法目的购买、使用“金币”。</p>
        <p>
          2、用户在使用本平台提供的充值服务时，如出现任何的涉嫌犯罪、违法违规、违反社会主义道德风尚、违反本协议或其他本平台对用户的管理规定的情形，本平台有权视您行为的性质及严重程度决定对您的帐号采取暂时或永久封禁措施。帐号封禁后至解禁（如有）前，您帐户上剩余的“金币”将被暂时冻结或全部扣除，不可继续用于购买本平台的产品及/或服务，同时不予返还您购买“金币”时的现金价值。
        </p>
        <h2>第四章 附则</h2>
        <p>
          1、为了向您提供更好的服务，或者基于法律规定、主管部门要求、业务开展情况等因素的更新与变化，本平台将适时对本协议内容进行修订，该等修订内容一经公布立即生效，本平台将通过本平台发出更新版本，您可通过“金币”充值页面随时查阅最新版本的协议条款。若您在本协议内容发生修订后继续使用本平台提供的充值服务的，则视为您同意最新修订的协议内容；否则请您立即停止充值。
        </p>
        <p>
          2、您理解并同意，本平台提供的充值服务是按照现有技术和条件所能达到的现状提供的，本平台会尽最大努力向您提供充值服务，确保充值服务的连贯性和安全性。但您亦知悉并认可，本平台不能随时或始终预见和防范技术及其他风险，包括但不限于因不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用充值服务以及其他的损失和风险；此外，除前述情况外，本平台有权视交易安全、运营规划等具体情况随时中断、中止或终止本协议项下充值服务，并将以本平台页面公告或其他适当方式向用户发出通知（而不再另行单独通知您）。
        </p>
        <p>
          3、因本协议引起的或与本协议有关的争议，均适用中华人民共和国法律。若本协议之任何规定因与中华人民共和国法律抵触或被有管辖权的人民法院认定而无效或不可执行，则在不违反法律的前提下，这些条款将按照尽可能接近本协议原条文意旨的原则重新解析，且本协议其它规定仍应具有完整的效力及效果。
        </p>
        <p>
          4、本协议签订地为北京市朝阳区。因本协议引起的或与本协议有关的争议，本平台与您将协商解决。协商不成的，任何一方均有权向本协议签订地所在地有管辖权的人民法院提起诉讼。
        </p>
      </div>
      <div class="purchase-main-buy" @click="closeGoldAgree">确认协议</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "contract",
  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    status(newV) {
      this.dialogStatus = newV;
    },
    "contractForm.convertPrice": {
      handler: function (newVal) {
        this.contractForm.capitalConvertPrice =
          this.common.convertCurrency(newVal);
      },
    },
  },
  data() {
    return {
      paramsData: {},
      contractForm: {
        buyName: "",
        sn: "",
        platform: "",
        platformVal: "",
        creditCode: "",
        legalPerson: "",
        idNumber: "",
        packageName: "",
        versionName: "",
        price: "",
        buyIdNum: "",
        buySemesterNum: "",
        capitalPrice: "",
        isDiscount: "否",
        capitalConvertPrice: "",
        convertPrice: "",
      },
      isCheck: false, // 是否同意条款
      buyPrice: 0,
      dialogStatus: false,
      jfDate: [],
      yfDate: [],
      contractNumber: "", //合同编号
      contractPrefix: "",
      contractUrl: "", //合同链接
      loading: false,
    };
  },
  mounted() {},
  methods: {
    closeGoldAgree() {
      this.$emit("close");
      this.dialogStatus = false;
    },
  },
};
</script>

<style lang="scss">
.contract {
  .el-dialog {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 40%;
    height: 670px;
  }
  .el-dialog__body {
    border-top: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    max-height: 85% !important;
    overflow-y: auto;
    //如需设置滚动条样式
    &::-webkit-scrollbar {
      width: 10px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #e1e1e1;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
  }
  .el-dialog__header {
    height: 30px;
    background: #b93138;
    // line-height:20px;
    font-size: 18px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }

    .el-dialog__headerbtn {
      // line-height:20px;

      .el-dialog__close {
        color: #ffffff;
      }
    }
  }
}
.hetong {
  h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
  }
  p {
    font-size: 16px;
    line-height: 32px;
    text-indent: 32px;
  }
}
.purchase-main-buy {
  margin: 30px auto 50px auto;
  width: 280px;
  height: 40px;
  background: linear-gradient(50deg, #b93037, #d44249);
  box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
  border-radius: 20px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
</style>