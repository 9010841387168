<template>
  <div class="purchase">
    <el-dialog
      title="充值金币"
      :visible.sync="dialogStatus"
      center
      width="40%"
      :before-close="closeDialog"
    >
      <div class="purchase-main">
        <div class="purchase-main-row">
          <div class="purchase-main-row-school">
            <h2>当前账户</h2>
            <p>
              {{ $store.state.schoolInfo.atteSchool.schoolName }}
              {{ $store.state.schoolInfo.atteSchool.contactsMobile }}
            </p>
          </div>
        </div>
        <div class="purchase-main-introduce">
          <h2>【金币介绍】</h2>
          <div class="purchase-main-introduce-content">
            <div class="purchase-main-introduce-img">
              <img
                src="../../../assets/img/product/Icon_COIN_LJCZ.png"
                alt=""
              />
            </div>
            <div class="purchase-main-introduce-txt">
              <p>金币可以用来购买系统平台的所有的产品和服务。</p>
              <p>
                金币仅能用于兑换系统平台现有的产品和服务，不能兑换现金，不能
                进行转账交易，不能兑换系统平台体系外的产品和服务。
              </p>
            </div>
          </div>
        </div>
        <div class="purchase-main-price">
          <div class="purchase-main-price-count">
            <h2>充值金币数<span>（1金币=1元）</span></h2>
            <p>实付金额</p>
          </div>
          <div class="purchase-main-price-num">
            <!-- <el-input-number
              v-model="num"
              @change="handleChange"
              :min="1"
              :max="10"
              label="描述文字"
            ></el-input-number> -->
            <div class="input_num">
              <div class="minus" @click="minus">
                <img src="../../../assets/img/product/Icon_Min.png" alt="" />
              </div>
              <div class="count">
                <el-input
                  type="number"
                  :min="0"
                  v-model="goldNum"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @change="bindCount"
                ></el-input>
                <!-- <input type="number" v-model="goldNum" @change="bindCount" /> -->
                <!-- {{ goldNum }} -->
              </div>
              <div class="add" @click="add">
                <img src="../../../assets/img/product/Icon_Add.png" alt="" />
              </div>
            </div>
            <p>{{ goldNum }}<span>元</span></p>
          </div>
        </div>
        <div class="purchase-main-type">
          <span class="purchase-main-type-name">支付方式</span>
          <div class="purchase-main-type-btn">
            <div class="purchase-main-type-btn-top">
              <div
                :class="currentType == item.id ? 'active' : ''"
                v-for="item in typeList"
                :key="item.id"
                @click="changeType(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="purchase-main-type-btn-cont" v-show="currentType == 2">
              <p>开户名：小牛启航体育(北京)有限公司成都分公司</p>
              <p>开户行：招商银行成都分行锦江支行</p>
              <p>银行账号：128913229510501</p>
            </div>
          </div>
        </div>
        <div class="purchase-main-agreement">
          <div class="purchase-main-agreement-select">
            <img
              :src="isAgreement ? Icon_Agree : Icon_Disagree"
              @click="bindAgree"
              alt=""
            />
            <p>同意<span @click="bindToAgree">《用户金币充值协议》</span></p>
          </div>
        </div>
        <div
          class="purchase-main-buy"
          @click="goContract(currentType, goldNum)"
        >
          确认支付
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
    goldCNum: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      Icon_Agree: require("@/assets/img/product/Icon_Agree.png"),
      Icon_Disagree: require("@/assets/img/product/Icon_Disagree.png"),
      isAgreement: true, // 是否同意金币协议
      goldNum: 0, // 金币总额
      min_num: 0, // 最小
      typeList: [
        // {
        //   name: "微信支付",
        //   id: 1,
        // },
        {
          name: "线下支付",
          id: 2,
        },
        // {
        //     name:'请款专用',
        //     id:3
        // },
      ],
      currentType: 2, // 1 线上支付  2 线下支付
      dialogStatus: false,
      packageId: null,
      orderInfo: {},
    };
  },
  watch: {
    goldCNum: {
      immediate: true,
      handler: function (newV) {
        this.goldNum = newV;
      },
    },
    status(newV) {
      this.dialogStatus = newV;
    },
    counter_num(newVal) {
      // console.log(newVal)
      this.$emit("input", newVal);
    },
    id(newV) {
      this.packageId = newV;
      if (this.packageId) {
        this.getPayInfo();
      }
    },
  },
  mounted() {
    this.packageId = this.id;
    if (this.packageId) {
      this.getPayInfo();
    }
  },
  methods: {
    changeType(data) {
      this.currentType = data.id;
    },
    goContract(type, num) {
      console.log(type, "11111111");
      if (num <= 0) {
        this.$notice({
          type: "error",
          message: "充值金币数必须大于0",
        }).isShow();
        return;
      }
      if (!this.isAgreement) {
        this.$notice({
          type: "error",
          message: "需要同意《用户金币充值协议》",
        }).isShow();
        return;
      }
      this.$emit("goldPay", type, num);
    },
    closeDialog() {
      this.$emit("close");
      this.goldNum = 0;
      this.isAgreement = true;
    },
    getPayInfo() {
      console.log(this.packageId);
      let params = {
        versionId: this.packageId,
      };
      this.api.product.getPayInfo(params).then((res) => {
        this.orderInfo = res.data;
      });
    },
    minus() {
      if (this.goldNum <= this.min_num) {
        this.goldNum = this.min_num;
      } else {
        this.goldNum--;
      }
    },
    add() {
      this.goldNum++;
    },
    // handleChange
    handleChange(val) {
      console.log(val);
    },
    // 同意金币协议
    bindAgree() {
      if (this.isAgreement) {
        this.isAgreement = false;
      } else {
        this.isAgreement = true;
      }
    },
    // 跳转页面
    bindToAgree() {
      console.log("我要跳转协议");
      this.$emit("bindAgreement");
    },
    bindCount() {
      if (this.goldNum == "") {
        this.goldNum = 0;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.purchase {
  .purchase-main {
    .purchase-main-row {
      @include flex(row, space-between, center);
      .purchase-main-row-school {
        padding-bottom: 30px;
        flex: 1;
        text-align: center;
        border-bottom: 1px solid #dddddd;
        h2 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-top: 18px;
        }
        > span:nth-child(2) {
          padding-left: 20px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .purchase-main-row-item-val {
          font-size: 28px !important;
          font-weight: bold;
          color: #b93138 !important;
          > span {
            padding-left: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    .purchase-main-introduce {
      padding: 30px 0 26px 0;
      h2 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .purchase-main-introduce-content {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-top: 12px;
        .purchase-main-introduce-img {
          margin-right: 20px;
          img {
            width: 92px;
            height: 92px;
          }
        }
        .purchase-main-introduce-txt {
          p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
            line-height: 26px;
          }
        }
      }
    }
    .purchase-main-price {
      .purchase-main-price-count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        h2 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          span {
            color: #888888;
          }
        }
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
      }
      .purchase-main-price-num {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 40px;
        .input_num {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 180px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 4px;
          .minus {
            width: 40px;
            height: 40px;
            border-right: 1px solid #dddddd;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .add {
            width: 40px;
            height: 40px;
            border-left: 1px solid #dddddd;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .count {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            flex: 1;
            /deep/ .el-input-number {
              width: auto;
            }
            /deep/ .el-input__inner {
              width: 100%;
              text-align: center;
              border: none;
              padding: 0;
            }

            /deep/ input::-webkit-outer-spin-button,
            /deep/ input::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
            }
            /deep/ input[type="number"] {
              -moz-appearance: textfield !important;
            }
          }
        }
        p {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #b93138;
          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
    .purchase-main-type {
      @include flex(row, flex-start, flex-start);
      .purchase-main-type-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
      }
      .purchase-main-type-btn {
        margin-left: 20px;
        .purchase-main-type-btn-top {
          @include flex(row, flex-start, center);

          > div {
            margin-right: 20px;
            width: 140px;
            height: 40px;
            line-height: 40px;
            background: #ffffff;
            border: 1px solid #395da4;
            border-radius: 20px;
            text-align: center;
            font-size: 16px;
            color: #333333;
            cursor: pointer;
          }
          .active {
            background: linear-gradient(232deg, #4e78ca, #395da4);
            box-shadow: 0px 3px 6px 0px #a9c6ff;
            color: #ffffff;
          }
        }
        .purchase-main-type-btn-cont {
          margin-top: 20px;
          font-size: 16px;
          color: #333333;
          > p {
            margin-bottom: 6px;
          }
        }
      }
    }
    .purchase-main-agreement {
      padding-top: 30px;
      .purchase-main-agreement-select {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          cursor: pointer;
        }
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          span {
            color: #b93138;
            cursor: pointer;
          }
        }
      }
    }
    .purchase-main-buy {
      margin: 20px auto 0 auto;
      width: 280px;
      height: 40px;
      background: linear-gradient(50deg, #b93037, #d44249);
      box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
      border-radius: 20px;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.purchase {
  .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }
  .el-dialog__header {
    height: 30px;
    background: #b93138;
    // line-height:20px;
    font-size: 18px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }

    .el-dialog__headerbtn {
      // line-height:20px;

      .el-dialog__close {
        color: #ffffff;
      }
    }
  }
}
</style>